.calendar-comp, .calendar-comp .fc {
  height: 81vh;
  height: calc(var(--vh, 1vh) * 80);
  margin-bottom: 8px;
}

@media only screen and (max-width: 860px) {
  .calendar-comp, .calendar-comp .fc {
    height: 67vh;
    height: calc(var(--vh, 1vh) * 66);
  }
}
