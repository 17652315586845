.loading-indicator-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-indicator-wrapper {
  height: 85vh;
  height: calc(var(--vh, 1vh) * 84);
  margin-bottom: 8px;
}

@media only screen and (max-width: 860px) {
  .loading-indicator-wrapper {
    height: 76vh;
    height: calc(var(--vh, 1vh) * 76);
  }
}